document.addEventListener("DOMContentLoaded", function () {
    const form = document.getElementById("contactForm");
    if(form){
        initForm(form)
    }
});

function initForm(form){
    const successMessage = document.getElementById("successMessage");
    const errorMessage = document.getElementById("errorMessage");
    const fields = form.querySelectorAll('input, textarea');

    fields.forEach(field => {
        field.addEventListener("input", () => validateField(field));
    });

    form.addEventListener("submit", function (event) {
        event.preventDefault();
        const formData = new FormData(form);

        var isValid = true
        fields.forEach(fields => {
            validateField(fields)
            if(fields.classList.contains("invalid")){
                isValid = false
            }
        })

        if(isValid){
            fetch("/api/contact.php", {
                method: "POST",
                body: formData
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.status == 'success') {
                    form.reset();
    
                    successMessage.classList.remove('hidden')
                    successMessage.classList.add('flex')
                }else{
                    errorMessage.classList.remove('hidden')
                    errorMessage.classList.add('flex')
                }
            })
            .catch(error => {
                errorMessage.classList.remove('hidden')
                errorMessage.classList.add('flex')
            })
            .finally(() => {
                setTimeout(function () {
                    successMessage.classList.remove('flex')
                    successMessage.classList.add('hidden')
    
                    errorMessage.classList.remove('flex')
                    errorMessage.classList.add('hidden')
                }, 5000)
            });
        }
    });
}

function validateField(field) {
    const value = field.value;
    const error = document.getElementById(`error-${field.id}`)
    var errorMessage = ''

    switch(field.id){
        case 'email':
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(!regex.test(value)){
                errorMessage = "Keine gültige E-Mail!"
            }
            if(value.length == 0){
                errorMessage = "Bitte geben Sie eine E-Mail ein." 
            }
            if(value.length > 255){
                errorMessage = "Maximale länge 255 Zeichen!" 
            }
            break;
        case 'subject':
            if(value.length == 0){
                errorMessage = "Betreff darf nicht leer sein!"
            }else if(value.length > 255){
                errorMessage = "Maximale länge 255 Zeichen!"
            }
            break;
        case 'message':
            if(value.length == 0){
                errorMessage = "Nachricht darf nicht leer sein!"
            }else if(value.length > 1500){
                errorMessage = "Maximale länge 1500 Zeichen!" 
            }
            break;
        default:
    }

    field.classList.remove("border-gray-300", "focus:ring-primary-500", "focus:border-primary-500")
    if(errorMessage){
        error.innerText = errorMessage
        field.classList.add("invalid", "border-red-500", "focus:border-red-500", "focus:ring-red-500");
        field.classList.remove("border-green-300", "focus:border-green-500", "focus:ring-green-500");
    } else{
        error.innerText = ""
        field.classList.remove("invalid", "border-red-500", "focus:border-red-500", "focus:ring-red-500");
        field.classList.add("border-green-300", "focus:border-green-500", "focus:ring-green-500");
    }
}